import '@kuzorov/smoothscroll-polyfill';
import Loadeer from "loadeer";
import { initcookies } from './components/cookies';
import { initSwipers } from './modules/swiperjs';
//import { initTickers } from './modules/ticker';
import Sketch from "./fake3d-master/js/src";

const aniTime = 400;
const aniDelay = 250;
const loadeerinstance = new Loadeer();
loadeerinstance.observe();


/* ready(function(){
    var t = window.setTimeout(animateIn, aniTime);
}); */

// handle regular loads
document.addEventListener("DOMContentLoaded", () => {
    var t = window.setTimeout(animateIn, aniDelay);
    //animateIn();
});

// handle back button
window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
    if (historyTraversal) {
        animateIn();
    }
});

function init() {
    console.log("init");

    // init fake 3D
    let fake3Dframes = document.querySelectorAll('.fake3d-frame');
    if (fake3Dframes.length > 0) {
        fake3Dframes.forEach(element => {
            new Sketch(element.querySelector('.f3d-canvas').id);
        });
    }

    // cookies
    const $body = document.querySelector('body');
    /*$body.addEventListener('cookies:saved', handleCookies); */
    // onCookieConsentInitialization
    initcookies(document.getElementById('btn_cookies'));

    initSwipers();
    //initTickers();

    // navi
    hamburger.addEventListener("click", mobileMenu);
    const navLink = document.querySelectorAll(".nav-link");
    navLink.forEach(n => n.addEventListener("click", closeMenu));

    //btt
    btn_btt = document.getElementById("btt");
    btn_btt.addEventListener('click', topFunction);
    //if (!$body.classList.contains("home")) {
    window.onscroll = function () { scrollFunction() };
    scrollStop(revert_btt, btt_revert_time);
    //}

    // transition routes
    setupRoutes();

    // contact    
    btn_contact_close = document.getElementById("modal_contact_close");
    btn_contact_close_overlay = document.getElementById("modal_contact_close_overlay");
    btn_contact_open = document.getElementById("modal_contact_open");
    btn_contact_close.addEventListener('click', hideModalContact);
    btn_contact_close_overlay.addEventListener('click', hideModalContact);
    btn_contact_open.addEventListener('click', showModalContact);
    //btn_contact_open.addEventListener('click', buildContactform);
}


// ANIMATION
function animateIn() {
    var w = document.querySelector('#wrapper');
    w.classList.add("animating");
    w.focus();
    setTimeout(function () { w.classList.add("transitioned"); }, aniTime + aniDelay);
}
function animateOut() {
    closeMenu();
    document.querySelector('#wrapper').classList.remove("animating");
}
function setupRoutes() {
    const navLink = document.querySelectorAll(".link-route");
    navLink.forEach(n => n.addEventListener("click", delayedClick));
}
function delayedClick(e) {
    e.preventDefault();
    //topFunction();
    animateOut();
    setTimeout(function (url) { window.location = url }, aniTime + aniDelay, this.href);
}

// COOKIES
function handleCookies(e) {
    var newcookies = e.detail.join();
    var oldcookies = decodeURIComponent(document.cookie).split('cookie_status=')[1];

    if (newcookies != oldcookies) {
        //location.reload(true);
    }
}

// BACK TO TOP
var btn_btt;
var btt_revert_time = 1500;
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        btn_btt.classList.add("showing");
    } else {
        btn_btt.classList.remove("showing");
    }
}
function topFunction() {
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
}
function revert_btt() {
    btn_btt.classList.remove("showing");
}

function scrollStop(callback, refresh = 66) {
    // Make sure a valid callback was provided
    if (!callback || typeof callback !== 'function') return;

    let isScrolling;
    window.addEventListener('scroll', function (event) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(callback, refresh);
    }, false);
}


// NAVIGATION
const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".menu__container");
const bod = document.body;

function mobileMenu() {
    console.log("clicking");
    hamburger.classList.toggle("active");
    navMenu.classList.toggle("active");
    bod.classList.toggle("nav-open");
}

function closeMenu() {
    hamburger.classList.remove("active");
    navMenu.classList.remove("active");
    bod.classList.remove("nav-open");
}

// CONTACT
var btn_contact_close;
var btn_contact_close_overlay;
var btn_contact_open;
const modal_contact = document.querySelector("#modal-contact");
function showModalContact() {
    if (!contactBuild)
        buildContactform();
    modal_contact.classList.add("active");
}

function hideModalContact() {
    modal_contact.classList.remove("active");
}

const form_container = document.querySelector('#form-container');
var contactBuild = false;
const buildContactform = async () => {
    if (!contactBuild) {
        let url = `${window.location.origin}/home.json`;
        try {
            const response = await fetch(url);
            const { html } = await response.json();
            setInnerHTML(form_container, html);
            initForms();
        } catch (error) {
            console.log('Fetch error: ', error);
        }

        contactBuild = true;
        btn_contact_open.removeEventListener('click', buildContactform);
    }
}
function setInnerHTML(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll("script")).forEach(oldScript => {
        const newScript = document.createElement("script");
        Array.from(oldScript.attributes)
            .forEach(attr => newScript.setAttribute(attr.name, attr.value));
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        oldScript.parentNode.replaceChild(newScript, oldScript);
    });
}

// Lightbox
Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
    element.onclick = (e) => {
        e.preventDefault();
        basicLightbox.create(`<img src="${element.href}">`, {
            className: `${element.getAttribute('lightboxinverted')}`,
        }).show();
    };
});

init();

Object.assign(window, { showModalContact, loadeerinstance, initSwipers });








