
export function initcookies(triggerButton) {
    //setup button
    triggerButton.addEventListener('click', function () {
        cc.show(100, true);
    });

    // onAccept
    window.addEventListener("cookieConsentAccept", e => {
        //console.log("accept", e.detail.cookie)
    }
    );

    // onChange
    window.addEventListener("cookieConsentChanged", e => {
        //console.log("changed", e.detail.cookie, e.detail.changed_categories)
    }
    );

    // onFirstAction
    window.addEventListener("cookieConsentFirstAction", e => {
        //console.log("firstAction", e.detail.cookie, e.detail.user_preferences)
    }
    );
}
