import Swiper from 'swiper';
import { Navigation, Pagination, EffectCards, EffectCreative, EffectFade, Autoplay } from 'swiper/modules';


export function initSwipers() {
  let swipers = document.querySelectorAll('.swiper:not(.project):not(.project-page)');
  let swipersProjects = document.querySelectorAll('.swiper.project');
  let swiperProject = document.querySelectorAll('.swiper.project-page');

  swipers.forEach(swiperEl => {
    let effect = swiperEl.getAttribute('effectType');
    let autoplaySettings = swiperEl.getAttribute('data-autoplay') == 'false' ? false : { delay: 6500, disableOnInteraction: false };
    //console.log(autoplaySettings);
    let swiper = new Swiper('#' + swiperEl.id, {
      modules: [Navigation, Pagination, EffectCards, EffectCreative, EffectFade, Autoplay],

      // Optional parameters
      loop: true,
      autoHeight: swiperEl.getAttribute('autoHeight'),
      speed: 1200,

      effect: (typeof effect !== 'undefined') ? effect : 'slide',

      creativeEffect: {
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },

      autoplay: autoplaySettings,

      // If we need pagination
      pagination: {
        el: swiperEl.querySelector('.swiper-pagination'),
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: swiperEl.querySelector('.swiper-button-next'),
        prevEl: swiperEl.querySelector('.swiper-button-prev'),
      },
    });
  });

  //  swiper inside a project
  swiperProject.forEach(swiperEl => {
    let effect = swiperEl.getAttribute('effectType');
    let swiper = new Swiper('#' + swiperEl.id, {
      modules: [Navigation, Pagination],

      // Optional parameters
      loop: false,
      autoHeight: false,
      speed: 1200,

      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,

      // If we need pagination
      pagination: {
        el: swiperEl.querySelector('.swiper-pagination'),
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: swiperEl.querySelector('.swiper-button-next'),
        prevEl: swiperEl.querySelector('.swiper-button-prev'),
      },
    });
  });


  swipersProjects.forEach(swiperEl => {
    //console.log(swiperEl.id);
    let effect = swiperEl.getAttribute('effectType');
    let swiper = new Swiper('#' + swiperEl.id, {
      modules: [Navigation, Pagination, EffectCards, EffectCreative, EffectFade],

      // Optional parameters
      loop: true,
      autoHeight: false,
      speed: 1200,

      effect: (typeof effect !== 'undefined') ? effect : 'slide',

      creativeEffect: {
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },

      // If we need pagination
      pagination: {
        el: swiperEl.querySelector('.swiper-pagination'),
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: swiperEl.querySelector('.swiper-button-next'),
        prevEl: swiperEl.querySelector('.swiper-button-prev'),
      },
    });
  });

  //initHomeSwiper();
}

function initHomeSwiper() {
  new Swiper('#swiper-home', {})
}


